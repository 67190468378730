import Phaser from 'phaser'
import MainScene from './scenes/MainScene';
import LoadScene from './scenes/LoadScene';

// import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.WEBGL,
	width: 1080,
	height: 2100,
    transparent: true,
	antialias: true,
	scale: {
		 mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
		//  parent: "game",
		 autoCenter: Phaser.Scale.CENTER_BOTH,
		 width: 1080,
		 height: 2100
	}, 
	loader: {
		crossOrigin: undefined
	},
	scene: [LoadScene,    
			MainScene],
	physics: {
		default: "arcade",
		arcade: {
			gravity: { x: 0, y: 0 }
		}
	}
}


// const firebaseConfig = {

// 	apiKey: "AIzaSyCpPhEsL_V9jCWrhLCbjeZOx7ibcQczClw",
  
// 	authDomain: "testproject-27800.firebaseapp.com",
  
// 	projectId: "testproject-27800",
  
// 	storageBucket: "testproject-27800.appspot.com",
  
// 	messagingSenderId: "943520765199",
  
// 	appId: "1:943520765199:web:8238d23b70f8c7ca751a7a",
  
// 	measurementId: "G-K4465J40C1"
// };


// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
  

export default new Phaser.Game(config)


