import Phaser, { Time } from 'phaser';
import UI from '../componenents/ui';
import EventsManager from "../utilities/EventsManager";
import ResultPanel from "../componenents/resultpanel";
import GameServer from '~/gameServer';
import axios from 'axios';

var randomGift;
var rewardType;
var voucherCode;
var rewardId;
var gameRefToken;
var gameServerInst = new GameServer();

export default class MainScene extends Phaser.Scene
{

	public emitter : any;
	gamePlayBg !: any;
	spinBtn !: any;
	spinBoard !: any;
	prizeText !: any;
	rewardType !: any;
	pin !: any;
	spinText !: any;
	chanceOneBar !: any;
	chanceTwoBar !: any;
	chanceThreeBar !: any;
	chanceBarArr !: any;
	totalChanceCount !: any;
	resultPanel !: any;
	usedBarArr !: any;
	gameRefToken !: any;
	bottle !: any;

	canSpin !: any;

	//class Obj
	uiObj !: any;
	initialPoints !: number;

	constructor()
	{
		super('MainScene')
		this.emitter = EventsManager.getInstance();
		this.chanceBarArr = [];
		this.usedBarArr = [];
		this.initialPoints = 600

		const url = window.location.href;
		const params = (new URL(url)).searchParams;
		gameRefToken = params.get('game_ref_token');

	}

	create() {
		this.gamePlayBg = this.add.image(this.cameras.main.width * -2.5,this.cameras.main.height * .5,"inGameBackground").setScale(1.25);
		this.loadHowToPlay();
	}

	loadHowToPlay() {
		this.uiObj = new UI(this,0,0);
		this.resultPanel = new ResultPanel(this,0,0);

	}

	addGamePlay() {
		this.canSpin = true;
		var _self = this;
	//	this.totalChanceCount = 0;

		this.spinBoard  = this.add.image(this.cameras.main.width * .5,this.cameras.main.height* .45,"spinBoard").setScale(0);

		var spinBpardTween =  this.tweens.add({
			targets:  this.spinBoard,
			scale:3,
			angle: 360,
			duration: 300,
			ease: "Sine.easeInOut",
			onComplete: function () {
				_self.pinBarAndBtn();
				//_self.addChanceBar();
			}
		});

	}

	pinBarAndBtn() {
		var _self = this;
		//this.pin = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .24,"pin").setScale(0.35);
		this.spinBtn = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .75,"letsgobtn").setScale(0.85).setInteractive().on('pointerdown', () => {
			if(_self.canSpin) {
			//	_self.totalChanceCount++;
				_self.canSpin = false;
				_self.spinTheBoard();
				_self.spinBtn.setAlpha(0.5);
				//_self.chanceBarArr[0].setAlpha(0)
				//_self.addUsedBar(_self.totalChanceCount,_self.chanceBarArr[0].x,_self.chanceBarArr[0].y);
			//	_self.chanceBarArr = _self.chanceBarArr.slice(1)
			}
		});

		this.spinText = this.add.text(
			this.cameras.main.width * .5,
			this.cameras.main.height * .75,
			"SPIN")
			.setFontFamily("RussoOne")
			.setColor("#000000")
			.setFontSize(60)
			.setOrigin(0.5)
			.setWordWrapWidth(900)
			.setAlign('center')

		this.bottle = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .44,"bottle").setScale(2.25);
	}

	addUsedBar(index,xPos,yPos) {
		let imgStr = "used"+index;
		var usedBar = this.add.image(xPos,yPos,imgStr).setScale(1.8);
		this.usedBarArr.push(usedBar);
	}

	spinTheBoard() {
		var gameOptions = {
			slices: 8,
			slicePrizes: ["object", "FIFA", "BLNT", "Eye glasses", "Wonder chef", "Beardo", "FIFA"],
			rotationTime: 3000
		}

		var rounds = Phaser.Math.Between(8, 10);

		//var randomGift = Phaser.Math.Between(1,7);
		// var randomGift = 4;

		randomGift;
		// rewardId = 4;
		// rewardType  = 3;
	
		if(rewardType == 1) {
			randomGift = gameOptions.slicePrizes.length - (2 - 1);
		} else if(rewardType == 2) {
			randomGift = gameOptions.slicePrizes.length - (1 - 1);
		} else if(rewardType == 3) {
			if(rewardId == 4) {
				randomGift = gameOptions.slicePrizes.length - (4 - 1);
			} else if(rewardId == 5) {
				randomGift = gameOptions.slicePrizes.length - (5 - 1);
			} else if(rewardId == 6) {
				randomGift = gameOptions.slicePrizes.length - (7 - 1);
			} else if(rewardId == 7) {
				randomGift = gameOptions.slicePrizes.length - (3 - 1);
			}
		}

		var eachSliceAngle = 360 / gameOptions.slices;

		var _self = this;

		this.tweens.add({

			targets: [this.spinBoard],

			angle: 360 * rounds + (randomGift * eachSliceAngle),

			duration: gameOptions.rotationTime,

			ease: "Circ.easeInOut",

			callbackScope: this,

			onComplete: function(tween){

			//	if(_self.totalChanceCount < 3) {
					_self.spinBtn.setAlpha(1);
					_self.canSpin = true;
			//	}
			// if(randomGift == 1)
			// 	randomGift++;
			//gameOptions.slicePrizes[gameOptions.slicePrizes.length - (randomGift - 1)
			_self.awardReward(randomGift);
			}
		});
	}

	getReward() {

	}

	spinTheBottle() {
		var gameOptions = {
			slices: 7,
			slicePrizes: ["object", "FIFA", "BLNT", "Eye glasses", "Wonder chef", "Beardo", "FIFA"],
			rotationTime: 3000
		}

		var rounds = Phaser.Math.Between(5, 8);

		var randomGift = Phaser.Math.Between(0,7);

		var eachSliceAngle = 360 / gameOptions.slices;

		var _self = this;

		this.tweens.add({

			targets: [this.bottle],

			angle: 360 * rounds + (randomGift * eachSliceAngle),

			duration: gameOptions.rotationTime,

			ease: "Expo.easeOut",

			callbackScope: this,

			onComplete: function(tween){

			//	if(_self.totalChanceCount < 3) {
					_self.spinBtn.setAlpha(1);
					_self.canSpin = true;
			//	}
			console.log("Random",randomGift)
				_self.resultPanel.addRewardPopup(randomGift)
			}
		});

	}

	addChanceBar() {
		this.chanceOneBar =  this.add.image(this.cameras.main.width * .33,this.cameras.main.height * .75,"chance1").setScale(1.8);
		this.chanceTwoBar = this.add.image(this.cameras.main.width * .505,this.cameras.main.height * .75,"chance2").setScale(1.8);
		this.chanceThreeBar = this.add.image(this.cameras.main.width * .68,this.cameras.main.height * .75,"chance3").setScale(1.8);
		this.chanceBarArr.push(this.chanceOneBar);
		this.chanceBarArr.push(this.chanceTwoBar);
		this.chanceBarArr.push(this.chanceThreeBar);	

	}

	toggleFullScreen() {
		var isAndroid = /Android/i.test(navigator.userAgent);
		if(isAndroid){
		if(!document.fullscreenElement !== undefined){
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
					}
				}
			}
		}
    }

	awardReward(reward) {

		console.log("reward",reward)
		// var isWin;
		// if(reward == "BetterLuck next time")
		// 	isWin = false;
		// else 
		// 	isWin = true;
		this.resultPanel.addRewardPopup(reward)
	}


	destroyGamePlayContents() {
		this.spinBoard.destroy();
		this.pin.destroy();
		this.spinBtn.destroy();

		this.chanceOneBar.destroy(); 
		this.chanceTwoBar.destroy();
		this.chanceThreeBar.destroy();
		this.usedBarArr[0].destroy();
		this.usedBarArr[1].destroy();
		this.usedBarArr[2].destroy();

		for(let i=0;i<this.chanceBarArr.length;i++) {
			this.chanceBarArr[i].destroy();
			this.usedBarArr[i].destroy();
		}
		this.usedBarArr.length = 0;
	}

	
    getWin() {
		console.log(gameRefToken)
        let axiosConfig  = {
            headers: {
                'Authorization': 'Bearer ' + gameRefToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json'
            }
        }

		var _self = this;

        axios.get('https://staging29api.bigcityexperiences.co.in/v1/game/getwin', axiosConfig).then(function (response) {              
            console.log("Response",response);
                rewardType = response.data.data.reward_type;
                rewardId = response.data.data.game_reward_id;
                voucherCode = response.data.data.voucher_code;
				console.log("GetWin",rewardType,rewardId,voucherCode)
          }).catch(function (error) {
              console.log("error",error)
			 _self.triggerServerPopup();
          });

    }


	triggerServerPopup() {
		var _self = this;
        // this.isWin = isWin;
        var greyScale = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * .5, "greyScale").setScale(0).setAlpha(0.8).setDepth(5000);
        var popup = this.add.image(this.cameras.main.width * .5, this.cameras.main.height * 2.5, "popup").setScale(2.25).setAlpha(0).setDepth(5001)


        _self.tweens.add({
            targets: greyScale,
            scale: 7,
            duration: 300,
            ease: "Sine.easeInOut",
            onComplete: function () {
                _self.tweens.add({
                    targets: popup,
                    y: _self.cameras.main.height * .9,
                    alpha: 0.85,
                    duration: 600,
                    ease: "Sine.easeInOut",
                    onComplete: function () {
                        _self.addServerErrorText();
                    }
                });
            }
        });
	}


	addServerErrorText() {
	    var serverErrorTitle = this.add.text(
            this.cameras.main.width * .5,
            this.cameras.main.height * .7,
            "SERVER ERROR!")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(100)
            .setOrigin(0.5)
            .setWordWrapWidth(900)
            .setAlign('center')
			.setDepth(5002)
	}


	submitGameReward() {
		
		let axiosConfig  = {
            headers: {
                'Authorization': 'Bearer ' + gameRefToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json'
            }
        }

		let data = {
				"game_reward_id": rewardId,
    			"voucher_code"  : voucherCode
		}

        axios.post('https://staging29api.bigcityexperiences.co.in/v1/game/submitgamereward',data, axiosConfig).then(function (response) {              
            console.log("Response",response);
          }).catch(function (error) {
              console.log("error",error)
          });
	}


}




