
export default class ResultPanel extends Phaser.GameObjects.Container {

    rewardPopup !: Phaser.GameObjects.Image;
    banner !: Phaser.GameObjects.Image;
    starLeft !: Phaser.GameObjects.Image;
    starMid !: Phaser.GameObjects.Image;
    starRight !: Phaser.GameObjects.Image;
    greyScale !: Phaser.GameObjects.Image;
    brandLogo !: any;
    scene: any;
    rewardItem !: any;
    rightStarScaleTween !: any;
    //Debug purpose
    indexCount !: number;

    voucherContent !: any;

    voucherTag !: any;

    betterLuckNextTimeText !: any;
    betterLuckNextTimeLine2 !: any;

    vouchertitle !: any;


    popup !: Phaser.GameObjects.Image;

    constructor(scene, x, y) {
        super(scene);
        this.scene = scene;
        //.........
        this.indexCount = 1;
    }

    addRewardPopup(item) {

        console.warn("item", item)

        var _self = this;
        // this.isWin = isWin;
        this.greyScale = this.scene.add.image(this.scene.cameras.main.width * .5, this.scene.cameras.main.height * .5, "greyScale").setScale(0).setAlpha(0.8);
        this.popup = this.scene.add.image(this.scene.cameras.main.width * .5, this.scene.cameras.main.height * 2.5, "popup").setScale(2.25).setAlpha(0)

        _self.scene.submitGameReward();

        _self.scene.tweens.add({
            targets: _self.greyScale,
            scale: 7,
            duration: 300,
            ease: "Sine.easeInOut",
            onComplete: function () {
                _self.scene.spinBtn.destroy();
                _self.scene.tweens.add({
                    targets: _self.popup,
                    y: _self.scene.cameras.main.height * .8,
                    alpha: 0.85,
                    duration: 600,
                    ease: "Sine.easeInOut",
                    onComplete: function () {
                        //Fix before push to server
                        _self.addRewardTextContent(item);
                    }
                });
            }
        });
    }


    addRewardTextContent(item) {

        //item = 1;
        // console.log("addRewardText",item)
        switch (item) {
            case 1:
                //Voucher code
                this.addVoucherCodeTitle();
1

                this.brandLogo = this.scene.add.image(
                    this.scene.cameras.main.width * .5, 
                    this.scene.cameras.main.height * .75,
                    "dailyobject"
                ).setScale(0.9)


                //DAILY OBJECTS
                this.voucherTag = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .65,
                    "DAILY OBJECTS")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(80)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .88,
                    "Flat extra 20% Off across the site")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(50)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                break;
            case 2:
                //FIFA TICKET
                this.addFiFaTitle()
                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .8,
                    "You have won 1 Lucky Draw Tickets to FIFA World Cup TM - tickets through spin the wheel")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(800)
                    .setAlign('center')

                break;
            case 3:
                //Beardo GLASS
                this.addVoucherCodeTitle();

                this.brandLogo = this.scene.add.image(
                    this.scene.cameras.main.width * .5, 
                    this.scene.cameras.main.height * .75,
                    "beardo"
                ).setScale(0.9)

                this.voucherTag = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .65,
                    "BEARDO")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(70)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .88,
                    "Flat 25% Off On cart value")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                break;
            case 4:
                //WONDER CHEF
                this.addVoucherCodeTitle();

                this.brandLogo = this.scene.add.image(
                    this.scene.cameras.main.width * .5, 
                    this.scene.cameras.main.height * .75,
                    "wonderchef"
                ).setScale(1.95)

                this.voucherTag = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .65,
                    "WONDER CHEF")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(70)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .88,
                    "15% off on purchases made above Rs 2000")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')


                break;
            case 5:
                //eyeglass
                this.addVoucherCodeTitle();

                this.brandLogo = this.scene.add.image(
                    this.scene.cameras.main.width * .5, 
                    this.scene.cameras.main.height * .73,
                    "eyemyeye"
                ).setScale(1.3)


                this.voucherTag = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .65,
                    "EYEMYEYE")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(70)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')

                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .88,
                    "Flat Rs.1000 Off on Eyeglasses & Sunglasses")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(900)
                    .setAlign('center')

                break;
            case 6:
                //BLNT
                this.addBLNT()

                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .8,
                    "You didnt qualify this time! Dont worry, just grab a budwiser and keep playing")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(800)
                    .setAlign('center')



                break;
            case 7:
                // FIFA
                this.addFiFaTitle()
                this.voucherContent = this.scene.add.text(
                    this.scene.cameras.main.width * .5,
                    this.scene.cameras.main.height * .8,
                    "You have won 1 Lucky Draw Tickets to FIFA World Cup TM - tickets through spin the wheel")
                    .setFontFamily("RussoOne")
                    .setColor("#FFFFFF")
                    .setFontSize(60)
                    .setOrigin(0.5)
                    .setWordWrapWidth(800)
                    .setAlign('center')



                break;
        }
    }

    addVoucherCodeTitle() {
        this.vouchertitle = this.scene.add.text(
            this.scene.cameras.main.width * .5,
            this.scene.cameras.main.height * .6,
            "VOUCHER CODE")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(100)
            .setOrigin(0.5)
            .setWordWrapWidth(900)
            .setAlign('center')

    }


    addFiFaTitle() {
        this.vouchertitle = this.scene.add.text(
            this.scene.cameras.main.width * .5,
            this.scene.cameras.main.height * .6,
            "CONGRATULATIONS")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(90)
            .setOrigin(0.5)
            .setWordWrapWidth(900)
            .setAlign('center')

    }

    addBLNT() {
        this.vouchertitle = this.scene.add.text(
            this.scene.cameras.main.width * .5,
            this.scene.cameras.main.height * .6,
            "SORRY!")
            .setFontFamily("RussoOne")
            .setColor("#FFFFFF")
            .setFontSize(90)
            .setOrigin(0.5)
            .setWordWrapWidth(900)
            .setAlign('center')

    }



}



