import axios from 'axios';

var rewardType;


export default class GameServer{

    constructor() {
    }

    getWin(gameRefToken) {
        let axiosConfig  = {
            headers: {
                'Authorization': 'Bearer ' + gameRefToken,
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/json'
            }
        }

        axios.get('https://appapi.budspacev4.bigcityvoucher.co.in/v1/game/getwin', axiosConfig).then(function (response) {              
            console.log("Response",response);
                rewardType = response.data.data.reward_type;
                // rewardId = response.data.data.game_reward_id;
                // voucherCode = response.data.data.voucher_code;
                // rewardArr.push(rewardType);

          }).catch(function (error) {
              console.log("error",error)
          });

    }






}


