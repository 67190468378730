
export default  class UI extends Phaser.GameObjects.Container {

   uiBG !: any;
   scene: any;
   logo !: any;
   letsgoBtn !: any;
   bulletPointOne !: any;
   bulletPointTwo !: any;
   bulletPointThree !: any;
   bulletPointFour !: any;

   bulletPointOneTxt !: any;
   bulletPointTwoTxt !: any;
   bulletPointThreeTxt !: any;
   bulletPointFourTxt !: any;
   uiBar !: any;
   rewardIcon !: any;
   rewardPageBG !: any;
   textArr !: any;
   popUp !: any;

   coin !: any;
   cancelBtn !: any;
   confirmBtn !: any;
   popupText !: any;
   totalPoints !: any;

   letsGoText !: any;

   constructor(scene,x,y) {
      super(scene,x,y);
      this.scene = scene;
      this.textArr = [];
      this.addHowPlay();
   }


   addHowPlay() {
      this.uiBG = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .5 , "inGameBackground").setScale(1.3);
      this.logo = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .15, "logo").setScale(2.2);
      this.addHowToPlayGameContent();

      this.letsgoBtn =  this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .65,"letsgobtn").setScale(0.85).setInteractive().on('pointerdown', () => {
         this.letsgoBtn.disableInteractive();
         this.letsgoBtn.destroy();
         this.destroyElements();
         this.fadeOutTransition()
         this.letsGoText.destroy();
         this.scene.getWin();
         this.logo.destroy();
      })

      this.letsGoText = this.scene.add.text(
         this.scene.cameras.main.width * .5,
         this.scene.cameras.main.height * .65,
         "LET'S GO")
         .setFontFamily("RussoOne")
         .setColor("#000000")
         .setFontSize(60)
         .setOrigin(0.5)
         .setWordWrapWidth(900)
         .setAlign('center')
   }


   addIngameUI() {
      var _self = this;
      //.21,.88
      this.uiBar = this.scene.add.image(-this.scene.cameras.main.width * 2.25,this.scene.cameras.main.height * .05,"uiBar").setScale(2.5);
      this.rewardIcon = this.scene.add.image(this.scene.cameras.main.width * 2.25,this.scene.cameras.main.height * .05, "leaderBoardIcon").setScale(0.25).setAlpha(0.5).setInteractive().on('pointerdown', () => {
         //_self.loadRewardPage();
      });

      var uiBarTween =  this.scene.tweens.add({
         targets:  this.uiBar,
         x: this.scene.cameras.main.width *.21,
         duration: 500,
         ease: "Linear",
         onComplete: function () {
            _self.totalPoints =   _self.scene.add.text(
               _self.scene.cameras.main.width *.21, 
               _self.scene.cameras.main.height * .05, 
               _self.scene.initialPoints)
               .setFontFamily("RussoOne")
               .setColor("#FFFFFF")
               .setFontSize(30)
               .setOrigin(0.5)
               .setWordWrapWidth(750)
         }
     });

     
     var rewardTween =  this.scene.tweens.add({
         targets: this.rewardIcon,
         x: this.scene.cameras.main.width * .88,
         duration: 500,
         ease: "Linear",
         onComplete: function () {
         }
  });

}


   destroyInGameUI() {
      var _self = this;
      var rewardPageTween =  this.scene.tweens.add({
         targets: [this.uiBar,this.rewardIcon],
         scale: 0,
         duration: 600,
         ease: "Linear",
         onComplete: function () {
            _self.uiBar.destroy();
            _self.rewardIcon.destroy();       
            _self.totalPoints.destroy();
            _self.addConfirmationPopup();  
         }
     });
   }


   addHowToPlayGameContent() {
      // this.bulletPointOne = this.scene.add.image(this.scene.cameras.main.width * .15,this.scene.cameras.main.height * .35,"bulletPoint1").setScale(1);
      // this.bulletPointOneTxt =   this.scene.add.text(
      //    this.scene.cameras.main.width * .53, 
      //    this.scene.cameras.main.height * .35, 
      //    "PAY A FEE OF 100 POINTS FROM YOUR WALLET TO PARTICIPATE")
      //    .setFontFamily("RussoOne")
      //    .setColor("#FFFFFF")
      //    .setFontSize(40)
      //    .setOrigin(0.5)
      //    .setWordWrapWidth(750)
      // this.textArr.push(this.bulletPointOneTxt)


      this.bulletPointTwo = this.scene.add.image(this.scene.cameras.main.width * .15,this.scene.cameras.main.height * .35,"bulletPoint1").setScale(1.5);
      this.bulletPointTwoTxt =   this.scene.add.text(
         this.scene.cameras.main.width * .39, 
         this.scene.cameras.main.height * .35, 
         "SPIN THE WHEEL")
         .setFontFamily("RussoOne")
         .setColor("#FFFFFF")
         .setFontSize(50)
         .setOrigin(0.5)
         .setWordWrapWidth(900)
      this.textArr.push(this.bulletPointTwoTxt)


      this.bulletPointThree = this.scene.add.image(this.scene.cameras.main.width * .15,this.scene.cameras.main.height * .45,"bulletPoint2").setScale(1.5);
      this.bulletPointThreeTxt =   this.scene.add.text(
         this.scene.cameras.main.width * .59, 
         this.scene.cameras.main.height * .45, 
         "STAND A CHANCE TO WIN AND EARN REWARDS")
         .setFontFamily("RussoOne")
         .setColor("#FFFFFF")
         .setFontSize(50)
         .setOrigin(0.5)
         .setWordWrapWidth(900)
      this.textArr.push(this.bulletPointThreeTxt)


      this.bulletPointFour = this.scene.add.image(this.scene.cameras.main.width * .15,this.scene.cameras.main.height * .55,"bulletPoint3").setScale(1.5);
      this.bulletPointFourTxt  =   this.scene.add.text(
         this.scene.cameras.main.width * .6, 
         this.scene.cameras.main.height * .55, 
         "REDEEM YOUR REWARDS AT THE END OF EACH GAME")
         .setFontFamily("RussoOne")
         .setColor("#FFFFFF")
         .setFontSize(50)
         .setOrigin(0.5)
         .setWordWrapWidth(800)
      this.textArr.push(this.bulletPointFourTxt)

   }


   loadRewardPage() {

      var _self = this;
      this.rewardPageBG = this.scene.add.image(this.scene.cameras.main.width * 3.5,this.scene.cameras.main.height * .5 , "uiBg").setScale(0.65);

      var rewardPageTween =  this.scene.tweens.add({
         targets: this.rewardPageBG,
         x: this.scene.cameras.main.width * .5,
         duration: 600,
         ease: "Linear",
         onComplete: function () {
            _self.addCurrentReward();
         }
     });

   }

   addCurrentReward() {

   }

   destroyElements() {
      for(let i=0;i<this.textArr.length;i++) {
         this.textArr[i].destroy();
      }
   }


   fadeOutTransition() {
      var _self = this;

      var dialogBGtween =  this.scene.tweens.add({
         targets: [this.uiBG,this.bulletPointOne,this.bulletPointTwo,this.bulletPointThree,this.bulletPointFour],
         x: this.scene.cameras.main.width * 2.52,
         duration: 500,
         ease: "Linear",
         onComplete: function () {
            _self.uiBG.destroy();
         }
     });

     var dialogBGtween =  this.scene.tweens.add({
      targets: this.scene.gamePlayBg,
      x: this.scene.cameras.main.width * 0.5,
      duration: 300,
      ease: "Linear",
      onComplete: function () {
     var dialogBGtween =  _self.scene.tweens.add({
         targets: _self.scene.gamePlayBg,
         x: _self.scene.cameras.main.width * 0.5,
         duration: 100,
         ease: "Linear",
         delay: 100,
      onComplete: function () {
         _self.scene.addGamePlay();
        // _self.addConfirmationPopup();
      }
         })
      }
   });

}


   addConfirmationPopup() {

      var _self = this;
      this.popUp = this.scene.add.image(this.scene.cameras.main.width * 2.25,this.scene.cameras.main.height * .5,"popupTwo")  
      
      this.scene.tweens.add({
         targets: this.popUp,
         x: _self.scene.cameras.main.width * 0.5,
         duration: 100,
         ease: "Linear",
         delay: 100,
      onComplete: function () {
         _self.addPopupContents();
      }
      })

      //on confirmation add the below functions

   }


   addPopupContents() {
      var _self = this;

      this.logo = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .15,"logo").setScale(0.55)

      this.coin = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .35,"coin").setScale(0.9)

      if(this.scene.initialPoints >= 100) {
      this.confirmBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .75,"confirm").setScale(.75).setInteractive().on('pointerdown', () => {
         _self.confirmBtn.disableInteractive();
         _self.scene.addGamePlay();
         _self.destroyPopupContent();
         _self.scene.initialPoints = _self.scene.initialPoints - 100;
         _self.addIngameUI();
      });
   } else {
      this.confirmBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .55,"confirm").setScale(.85).setAlpha(0.5)
   }


      this.cancelBtn = this.scene.add.image(this.scene.cameras.main.width * .5,this.scene.cameras.main.height * .65,"cancel").setScale(.85).setInteractive().on('pointerdown', () => {
         _self.destroyPopupContent();
         _self.addHowPlay();

      });

      this.popupText =  this.scene.add.text(this.scene.cameras.main.width * .5, this.scene.cameras.main.height * .45, "Use 100 points from your wallet to start spinning").setFontFamily("RussoOne").setColor("#000000").setFontSize(50).setOrigin(0.5).setWordWrapWidth(800)

   }


   destroyPopupContent() {
      this.coin.destroy();
      this.logo.destroy();
      this.confirmBtn.destroy();
      this.cancelBtn.destroy();
      this.popupText.destroy();
      this.popUp.destroy();
   }

}




