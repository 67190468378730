import  config  from "../main"

export default class LoadScene extends Phaser.Scene
{

    bg !: Phaser.GameObjects.Image;
    title !: Phaser.GameObjects.Image;
    salesFigure1 !: Phaser.GameObjects.Image;
    salesFigure2 !: Phaser.GameObjects.Image;
    loadComplete !: any;
	sponsor !: Phaser.GameObjects.Image;
    value !: any;
	logo !: any;
	promoter !: Phaser.GameObjects.Image;


	constructor()
	{
        super({
			key: 'loader',
			pack: {
				files: [
					{
						type: 'image',
						key: "bg",
						url: "assets/backGround.png"
					},
					{
						type: 'image',
						key: "logo",
						url: "assets/logo.png"
					}
				]
			}
		});
	}

    preload() {	
		this.bg = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .5,"bg").setScale(1.35);	
		this.logo = this.add.image(this.cameras.main.width * .5,this.cameras.main.height * .2,"logo").setScale(0.85);
		this.loadImages();
		this.loadProgressBar();
    }

	loadImages() {
		this.load.image("inGameBackground", "assets/backGround.png");
		this.load.image("bulletPoint1", "assets/bulletPoint1.png");
		this.load.image("bulletPoint2", "assets/bulletPoint2.png");
		this.load.image("bulletPoint3", "assets/bulletPoint3.png");
		this.load.image("bulletPoint4", "assets/bulletPoint4.png");
		this.load.image("letsgobtn", "assets/button.png");
		this.load.image("spinBoard", "assets/spinBoard.png");
		this.load.image("bottle","assets/bottle.png");
		this.load.image("popup", "assets/Onboarding.png")
		this.load.image("greyScale","assets/greyScale.jpg");


		this.load.image("beardo","assets/beardo.png");
		this.load.image("dailyobject","assets/Dailyobject.jpg");
		this.load.image("eyemyeye","assets/eyemyeye.png");
		this.load.image("wonderchef","assets/wonderchef.png");
	}


	
    loadProgressBar() {
     
		let progressBar = this.add.graphics();
		let progressBox = this.add.graphics();
		progressBox.fillStyle(0x222222, 0.1);
		progressBox.fillRect(
			config.scale.width / 2 - 150,
			config.scale.height / 2 - 30,
			320,
			50
		)

		this.load.on("progress", function (value) {
			progressBar.clear();
			progressBar.fillStyle(0xffffff, 1);
			progressBar.fillRect(
				config.scale.width / 2 - 140,
				config.scale.height / 2 - 20,
				300 * value,
				30
			)
			//percentText.setText(parseInt(value * 100) + " %");
		});

		this.load.on("fileprogress", function (file) { });
		this.loadComplete = false;
		var _self = this;
		this.load.on("complete", function () {
			 progressBar.destroy();
			 progressBox.destroy();
			 _self.logo.destroy();
			 _self.bg.destroy();
		});
	}

    create() {
        this.scene.start("MainScene");
    }


}


